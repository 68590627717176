<template>
  <div class="newauth">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信用授权新增</div>
    <div class="card">
        <div class="catalog">
            <p>选择市与银行<span class="key">*</span></p>
            <div class="select">
                <select v-model="guideCity">
                    <option value="">请选择</option>
                    <option value="450100">南宁市</option>
                    <option value="450200">柳州市</option>
                    <option value="450300">桂林市</option>
                    <option value="450400">梧州市</option>
                    <option value="450500">北海市</option>
                    <option value="450600">防城港市</option>
                    <option value="450700">钦州市</option>
                    <option value="450800">贵港市</option>
                    <option value="450900">玉林市</option>
                    <option value="451000">百色市</option>
                    <option value="451100">贺州市</option>
                    <option value="451200">河池市</option>
                    <option value="451300">来宾市</option>
                    <option value="451400">崇左市</option>
            </select>
            </div>           
        </div>
        <div class="catalog">
            <p></p>
            <div class="select">
                <select v-model="guideBranch">
                    <option value="">请选择</option>
                    <option v-for="item in guideBranchList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
                </select>
            </div>           
        </div>
        <div class="catalog">
            <p></p>
            <div class="select">
                <select v-model="guideAgency">
                    <option value="">请选择</option>
                    <option v-for="item in guideAgencyList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
                </select>
            </div>           
        </div>
        <div v-for="(item,index) in bankCodeList" :key="item">
            <div class="catalog">
                <p>选中行<span class="key">*</span></p>
                <input type="text" :value="item.orgName" disabled>           
            </div>
            <div style="text-align: right;">
                <div class="del" @click="delRow(index)"><img src="../../assets/img/del_w.png"><p>移除</p> </div>
            </div>
        </div>
        <div class="catalog">
            <p>授权时间</p>
            <div class="radio">
                <van-radio-group v-model="checked" direction="horizontal">
                    <van-radio name="1">一个月</van-radio>
                    <van-radio name="2">两个月</van-radio>
                    <van-radio name="3">三个月</van-radio>
                    <van-radio name="0">停止授权</van-radio>
                </van-radio-group>
            </div>        
        </div>     
             
        <div style="text-align: center;padding: 4vw 0;">
            <div class="edit" @click="saveNewAuth()">保存</div>
        </div>      
    </div>
    
    
  </div>
</template>
<script>
import { ref,reactive,toRaw,toRefs,watch } from 'vue';
import { apiBranchBanks,apiSubBranchBanks,apiBankendtimeSave } from '../../api/axios';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
export default {
   setup() {
    let router = useRouter();
    const checked = ref('1');
    const state = reactive({
        guideCity: '',
        guideBranch: '',
        guideAgency: '',
        guideBranchList: [],
        guideAgencyList: []
    });
    const bankCodeList = ref([]);
    //监听所属市变化
    watch(()=> [state.guideCity,state.guideBranch,state.guideAgency], ([new2,new3,new4],[old2,old3,old4] ) => {
            if(new2!=old2) {
                if(new2 != '') {                    
                    apiBranchBanks({cityCode: new2}).then(res => {                
                        if(res.code === '1'){
                            state.guideBranchList = res.info.bankList;
                            state.guideAgencyList.splice(0,state.guideAgencyList.length);
                            state.guideAgency = '';
                            state.guideBranch = '';                            
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideBranchList.splice(0,state.guideBranchList.length);
                    state.guideBranch = '';
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    state.guideAgency = '';
                }
                
            };
            if(new3 != old3) {
                if(new3 != '') {
                    apiSubBranchBanks({branchBankCode: new3}).then(res => {
                        if(res.code === '1'){
                            state.guideAgencyList = res.info.subBankList;
                            state.guideAgency = '';  
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    state.guideAgency = '';  
                }
            };
            if(new4 != old4) {
                if(new4 != '') {
                    if(bankCodeList.value.length < 3){
                    let arrIndex = bankCodeList.value.findIndex(item => {
                        return item.orgCode === new4
                    });
                    if (arrIndex > -1) {
                        Toast('已经添加行了');
                    } else {
                        let obj = toRaw(state.guideAgencyList).find(o => o.orgCode === new4);
                        bankCodeList.value.push(obj);
                        // console.log(bankCodeList.value,996)
                    }          
                }else {
                    Toast('最多只能添加3个');
                } 
                }
            }

        })

    const delRow = (i) => {
        // console.log(i);
        bankCodeList.value.splice(i,1);
    }
    const saveNewAuth = () => {
        if(bankCodeList.value.length ===0) {
            Toast('请先选择授权银行');
            return;
        }
        let save = {
            bankCodeList: [],
            authTime: checked.value
        }
        bankCodeList.value.forEach((item) => {
            save.bankCodeList.push(item.orgCode);
        })
        apiBankendtimeSave(save).then(res => {                
            if(res.code === '1'){
                Toast('新增授权成功');
                router.go(-1); 
            }
            else {
                Toast(res.msg);
            }           
        });
    }
    return {
        ...toRefs(state),
        bankCodeList,
        checked,
        delRow,
        saveNewAuth
    };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },

  }
}
</script>
<style lang="less" scoped>
.newauth {
    padding-top: 12.5vw;
    .card {
        padding: 2vw 4vw;
        .catalog {
            display: flex;
            align-items: center;
            p {
                width: 26.5vw;
                color: #666666;
                font-size: 4vw;
                min-height: 4.5vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }

            select,input {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 3.5vw;
                width: 54.5vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            .radio {
                width: 54.5vw;
                margin-left: 1vw;
            }

        }
        .del {
                display: inline-flex;
                align-items: center;
                color: #fff;
                border-radius: 4vw;
                background: #63ccfc;
                font-size: 4vw;
                line-height: 4vw;
                padding: 1vw 2vw;
                img {
                    width: 4.5vw;
                    height: auto;
                    margin-right: 1vw;
                }
            }
        .edit {
            display: inline-block;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 1vw 6vw;
            border-radius: 5vw;
            border: 0;
        }
        
    }
    
    
}
</style>
<style lang="less">
.newauth {
     .van-radio__icon--checked .van-icon,
        .van-radio__icon .van-icon {
            display: none;
        }
        .van-radio__label {
            background-color: #f5f5f5;
            font-size: 4vw;
            padding: 2vw 2vw;
            border-radius: 1vw;
            margin-top: 2vw;
        }
        .van-radio[aria-checked="true"] .van-radio__label {
            color: #fff;
            background: linear-gradient(to right, #fcaf3c, #ff9900);
        }
        .van-radio--horizontal {
            margin-right: 0;
        }
}
</style>